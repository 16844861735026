@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Nunito Sans';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:focus-visible {
  outline: 0;
}

.setting-header {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  margin-top: 20px;
  letter-spacing: 1px;
  padding-bottom: 20px;
}

.general-crosshair-wrapper {
  position: relative;
}

.general-crosshair-wrapper .crosshair-title {
  position: absolute;
  top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.crosshair-title span {
  font-weight: bold;
  font-size: 1.1rem;
  background: #00000080;
  color: white;
  text-transform: uppercase;
  padding: 4px 8px;
}

body {
  user-select: none; /* Standard syntax */
}

.color-text-display {
  display: flex;
  flex: 1;
}
.color-text-display input {
  width: 100%;
}
.color-display {
  width: 70px;
}
.color-picker-tooltip {
  position: absolute;
  z-index: 2;
  top: 64px;
}
.color-picker-tooltip input {
  color: black;
  width: 100% !important;
}
.cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.color-dropdown {
  position: absolute;
  z-index: 2;
  display: none;
  top: 64px;
  width: 100%;
}
.color-dropdown li {
  position: relative;
  z-index: 4;
  background-color: #ccc;
  color: black;
  font-weight: bold;
  width: 100%;
  padding: 2px;
  display: flex;
  align-items: center;
}
.color-dropdown li:hover,
.color-dropdown li:nth-child(even):hover {
  background-color: yellow;
}

.color-dropdown li:nth-child(even) {
  background-color: #ddd;
}

.color-dropdown.open {
  display: block;
}
.selected-color {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 16px;
}
.mini-color {
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #00000050;
  margin-right: 5px;
  margin-left: 5px;
}

.no-bg .setting-row {
  background-color: none;
}
.setting-row {
  color: white;
  height: 64px;
  transition: background-color 0.2s;
  user-select: none;
}

.setting-row:nth-child(even),
.setting-row.odd {
  background-color: #ffffff08;
}
.setting-row:not(.no-hover):hover {
  background-color: #ffffff20;
}
.setting-row input[type='radio'] {
  display: none;
}

.setting-row input[type='number'],
.setting-row input[type='text'] {
  background: transparent;
  text-align: center;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.setting-row .radio {
  flex: 1;
  display: grid;
  place-items: center;
  background-color: #ffffff05;
  transition: background-color 0.2s;
  &.checked {
    background-color: #ffffff20;
    &:hover {
      background-color: #ffffff22;
      cursor: default;
    }
  }
  &:hover {
    background-color: #ffffff20;
    cursor: pointer;
  }
}
.setting-value {
  background-color: #ffffff12;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: 0 0 0 0;
}
.rc-slider {
  & .rc-slider-rail {
    height: 0;
    top: -1px;
    &:hover {
      cursor: pointer;
    }
    &::before {
      content: '';
      display: block;
      height: 6px;
      background: white;
      margin-left: 4px;
      position: relative;
      top: 5px;
      border-radius: 5px;
    }
    &::after {
      content: '';
      display: block;
      height: 14px;
      background: #ffffff40;
      position: relative;
      border-radius: 8px;
      top: -5px;
    }
  }
  & .rc-slider-handle {
    border: 0;
    background: white;
    opacity: 1;
    height: 12px;
    width: 12px;
    top: 6px;
  }
  & .rc-slider-track {
    height: 0;
  }
}
