:root {
  --text-color: #c0ccd2;
  --text-active-color: #eaeeb3;
}
.bg {
  background-image: url('./background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  &::after {
    content: '';
    backdrop-filter: blur(10px);
    display: block;
    height: 100%;
    width: 100%;
  }
}

.modal-backdrop {
  backdrop-filter: blur(3px);
  z-index: 4;

  position: fixed;
  inset: 0px;
}
.modal-content {
  background: #1e2328;
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-top: 1px solid white;
  border-bottom: 1px solid #8e9093;
  overflow: auto;
  outline: none;
  padding: 10px;
  padding-bottom: 30px;
  width: 100%;
  max-width: 1000px;
}
.modal-content textarea {
  background: #55595d;
  padding: 10px;
  resize: none;
  color: white;
  font-size: 1.4rem;
  width: 80%;
  height: 175px;
}
.modal-content .title {
  text-transform: uppercase;
  color: #f7f7c7;
  font-weight: 600;
  font-size: 2.2rem;
  letter-spacing: 2px;
  text-align: center;
}

.modal-close-btn {
  width: 68px;
  height: 68px;
  background-color: #34383d;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.modal-close-btn .corner {
  display: block;
  width: 20px;
  height: 2px;
  background-color: white;
  position: absolute;
  transition: all 0.5s ease;
}

.modal-close-btn .corner.top-left {
  top: 12px;
  left: 4px;
  transform: rotate(45deg);
}
.modal-close-btn .corner.top-right {
  top: 12px;
  right: 4px;
  transform: rotate(-45deg);
}
.modal-close-btn .corner.bottom-left {
  bottom: 12px;
  left: 4px;
  transform: rotate(-45deg);
}
.modal-close-btn .corner.bottom-right {
  bottom: 12px;
  right: 4px;
  transform: rotate(45deg);
}
.modal-close-btn:hover .corner.top-left {
  top: 8px;
  left: 0px;
}
.modal-close-btn:hover .corner.top-right {
  top: 8px;
  right: 0px;
}
.modal-close-btn:hover .corner.bottom-left {
  bottom: 8px;
  left: 0px;
}
.modal-close-btn:hover .corner.bottom-right {
  bottom: 8px;
  right: 0px;
}
.modal-close-btn:hover .center {
  height: 7px;
  width: 7px;
}
.modal-close-btn:hover {
  background-color: #3f4448;
}
.modal-close-btn .center {
  width: 5px;
  height: 5px;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
}

.big-btn {
  cursor: pointer;
  width: 400px;
  height: 84px;
  display: grid;
  place-items: center;
  border: 3px solid #55595d;
  background: #505458;
  position: relative;
  transition: all 0.5s ease;
  font-size: 2rem;
  text-transform: uppercase;
  color: white;
  text-shadow: 2px 2px 0px #00000050;
  font-weight: 600;
}

.big-btn.confirm {
  background-color: #6fafa3;
}
.big-btn.confirm:hover:not(:disabled) {
  background-color: #85b49f;
}
.big-btn:disabled {
  background-color: #2d3135;
  cursor: default;
}

.big-btn .corner {
  height: 2px;
  width: 2px;
  background: white;
  position: absolute;
  transition: all 0.5s ease;
}
.big-btn .corner.top-left {
  top: -2px;
  left: -2px;
}
.big-btn .corner.top-right {
  top: -2px;
  right: -2px;
}
.big-btn .corner.bottom-left {
  bottom: -2px;
  left: -2px;
}
.big-btn .corner.bottom-right {
  bottom: -2px;
  right: -2px;
}
.big-btn:hover:not(:disabled) .corner.top-left {
  top: 5px;
  left: 5px;
}
.big-btn:hover:not(:disabled) .corner.top-right {
  top: 5px;
  right: 5px;
}
.big-btn:hover:not(:disabled) .corner.bottom-left {
  bottom: 5px;
  left: 5px;
}
.big-btn:hover:not(:disabled) .corner.bottom-right {
  bottom: 5px;
  right: 5px;
}
.big-btn:hover:not(:disabled) {
  border-color: #eaeeb2;
  background-color: #6f736a;
}

.crosshair-form {
  max-width: 1334px;
}

.crosshair-bg {
  background-image: url('./crosshairbg.jpg');
  background-position: center center;
  height: 200px;
  width: 100%;
  max-width: 1334px;
}
.x-tabs {
  color: ;
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.x-tabs li {
  padding: 10px;
  font-weight: 600;
  margin: 0 40px;
}
.x-tabs a {
  padding: 0 20px;
  color: var(--text-color);
}
.x-tabs a:hover {
  color: white;
}
.x-tabs li.selected a {
  color: var(--text-active-color);
}
.scrollbar {
  position: relative;
  height: calc(90vh - 366px);
  overflow: auto;
  padding-right: 20px;
  margin-top: 20px;
}

.disabled-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 260%;
  width: calc(100% - 10px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.crosshair {
  z-index: 2;
}
.setting-row .icon-btn {
  margin: 5px;
}
.sketch-picker {
  padding: 10px 10px 0px !important;
  box-sizing: initial;
  background: #ffffff20 !important;
  border-radius: 0 !important;
  backdrop-filter: blur(6px);
  padding: 18px !important;
}
.duplicate-text {
  color: #73b1a5;
}
.modal-text {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 1px;
}
.sketch-picker label {
  color: white !important;
  font-weight: bold;
  text-shadow: 1px 1px 0px #00000080 !important;
}

.crosshair-item {
  margin: 5px;
  padding: 10px;
  border: 1px solid #00000040;
  background: #ffffff08;
  transition: all 0.2s ease;
}
.crosshair-item:hover {
  background: #ffffff16;
}

.icon-btn {
  width: 52px;
  height: 52px;
  background: #ffffff20;
  cursor: pointer;
  transition: all 0.2s ease;
  display: grid;
  place-items: center;
  position: relative;
}
.icon-btn.delete span {
  color: #f09fa6;
}
.icon-btn.export span,
.icon-btn.import span {
  color: #deebff;
}
.icon-btn .tool-tip {
  display: none;
  position: absolute;
  top: 52px;
  left: 0;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 8px;
  width: 150px;
  background: #00000090;
  text-transform: uppercase;
  z-index: 2;
}
.icon-btn:hover .tool-tip:not(.copy) {
  display: block;
}
.icon-btn .copy:not(.hidden) {
  width: 300px;
  left: -125px;
  text-align: center;
  display: block;
}
.icon-btn:hover .tool-tip.hidden {
  display: none;
}
.icon-btn:hover {
  background-color: #ffffff40;
}
.icon-btn:active {
  background-color: #ffffff10;
}
.crosshair-profile {
  display: flex;
  margin-right: 24px;
}
.crosshair-profile .separator {
  width: 1px;
  height: 45px;
  background: white;
  margin: 8px 5px;
}
.link-btn a {
  background: #ffffff50;
  border-radius: 12px;
  margin: 0 20px;
  vertical-align: middle;
  width: 40px;
  text-align: center;
  display: inline-block;
}
.sniper-overlay .x {
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
}
.sniper-overlay .y {
  width: 2px;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
}
.sniper-overlay .bar {
  height: 10px;
  width: 140px;
  top: calc(50% - 5px);
  background: black;
  position: absolute;
}

.sniper-overlay .bar.left {
  left: 0;
}
.sniper-overlay .bar.right {
  right: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #ffffff40;
  padding: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}
.selected-color {
  background: #ffffff10;
}
.navbar {
  height: 60px;
  border-bottom: 1px solid #ffffff40;
  margin-bottom: 20px;
  color: var(--text-color);
  & ul {
    display: flex;
    justify-content: center;
    height: 100%;
    & li {
      display: flex;
      align-items: center;
      padding: 0 20px;
      position: relative;
      cursor: pointer;
      &.selected a {
        color: var(--text-active-color);
      }
      & a {
        color: white;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
      }
      &::after {
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        background: white;
        rotate: 45deg;
        position: absolute;
        left: 50%;
        bottom: -4px;
      }
    }
  }
}

.overlay-for-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
}
.overlay-for-input:hover {
  background: #ffffff10;
}
.profile-dropdown {
  position: absolute;
  z-index: 2;
  top: 60px;
  background-color: #ccc;
  width: 100%;
  color: black;
  font-weight: bold;
}
.overlay-for-dropdown {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.profile-dropdown li {
  padding: 2px;
  z-index: 2;
}
.profile-dropdown li:nth-child(even) {
  background-color: #bfbfbf;
}

.profile-dropdown li.active {
  background-color: #dee2a9 !important;
}
.profile-dropdown li:hover {
  background-color: #d3d7a1 !important;
}

.crosshair-form .profile-input[type='text'] {
  width: 100%;
  text-align: left;
  padding-left: 10px;
}
.delete-text {
  color: #f15b56;
}
